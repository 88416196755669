var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h3',{style:(`
        font-size: 16px;
        text-align: justify;
        line-height: 1.5;
        margin-bottom: 10px;
        color: #464646;
        font-weight: normal;
        padding-top: 5px;
        padding-left: ${_vm.needIndex ? 30 : 10}px;
        padding-right: 10px;
    `)},[(_vm.needIndex)?_c('span',{staticStyle:{"font-size":"16px","text-align":"right","line-height":"1.5","margin-bottom":"10px","color":"#333","font-weight":"border","width":"30px","height":"100%","float":"left","margin-left":"-30px","padding-right":"6px","box-sizing":"border-box"}},[_vm._v(" "+_vm._s(_vm.index)+". ")]):_vm._e(),_c('span',{staticStyle:{"font-size":"16px","text-align":"justify","line-height":"1.5","margin-bottom":"10px","color":"#464646","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.desp)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }